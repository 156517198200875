import React from 'react';
import { useTranslation } from "react-i18next";

import LineItem from '../components/lineItem';

import * as Styles from '../styles';
import * as Divider from '../../../styles/dividerStyles';

import draftBeerList from '../../../menu/draftBeerList';
import craftBeerList from '../../../menu/craftBeerList';

export default function PageBeer() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_beer_tag')}>
      <h2>{t('menu-title_beer')}</h2>

      <DraftBeer/>
      <Divider.H20/>
      <CraftBeer/>
    </Styles.PageMenu>
  );
}

const DraftBeer = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('menu-draft_beer')}</h3>

      <table>
        <thead>
          <tr>
            <th/>
            <th style={{textAlign: "right"}}>0.30 cl</th>
            <th style={{textAlign: "right"}}>0.40 cl</th>
            <th style={{textAlign: "right"}}>1 l</th>
            <th style={{textAlign: "right"}}>3 l</th>
          </tr>
        </thead>

        <tbody>
        { draftBeerList.map((beer, i) =>
          <tr key={i}>
            <td>{beer.name}</td>
            <td style={{ textAlign: "right" }}><strong>{beer.price02} €</strong></td>
            <td style={{ textAlign: "right" }}><strong>{beer.price04} €</strong></td>
            <td style={{ textAlign: "right" }}><strong>{beer.price1} €</strong></td>
            <td style={{ textAlign: "right" }}><strong>{beer.price3} €</strong></td>
          </tr>
        )}
        </tbody>
      </table>
    </>
  );
}

const CraftBeer = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('menu-craft_beer')}</h3>

      { craftBeerList.map((item, i) =>
        <div key={i}>
          <div className='clearfix'>
            <h4>{item.category}<span style={{float: "right"}}> 0.33 cl</span></h4>
          </div>

          { item.beers.map((beer, i) =>
            <LineItem
              key={i}
              name={beer.name}
              price={<>{beer.price} €</>}
            />
          )}
        </div>
      )}
    </>
  );
}